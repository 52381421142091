<template>
    <div>
        <h2>Detección Facial en Tiempo Real</h2>
        <video ref="video" autoplay></video>
        <canvas ref="canvas"></canvas>

        <h2>Persona Detectada</h2>
        <p v-if="recognizedPerson">Detectado: {{ recognizedPerson }}</p>
        <p v-if="faceExpression">Expresión: {{ faceExpression }}</p>

        <v-btn @click="startCamera">Encender Cámara</v-btn>
        <v-btn @click="stopCamera">Apagar Cámara</v-btn>
        <v-btn @click="focusOnMainPerson">Enfocar en la persona principal</v-btn>
        <v-btn @click="showAllPeople">Mostrar todas las personas</v-btn>
        <br>
        <AlertSunshine :totalItems="ArrayDNI.length" :countItems="count" :dialog="dialog" />
        <v-dialog v-if="dialogAreID" v-model="dialogAreID" max-width="500px" persistent>
            <v-card>
                <s-toolbar label="Seleccione Area" dark color="#8e8f91" save @save="getImage()"></s-toolbar>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-select-area 
								label=""
								v-model="areID"
							/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import * as faceapi from 'face-api.js';
import _sHelper from "@/services/HelperService";
import FacialReconognitionService from '../../services/Security/FacialReconognitionService';
import GenUploadFiles from '../../services/General/GenUploadFiles';
import _sGenParam from "../../services/General/ParameterService";

export default {
    data() {
        return {
            modelsLoaded: false,
            recognizedPerson: "",
            faceExpression: "",
            ArrayDNI: [],
            ArrayImg: [],
            labeledDescriptors: [],
            isCameraActive: true,
            focusOnMain: false,
            lastFacePosition: null,
            noMovementCount: 0,
            urlImgs: null,
            count: 0,
            dialog: false,
            dialogAreID: true,
            areID: null
        };
    },
    async mounted() {
        await this.loadModels();
        // await this.getImage(); // Obtenemos las imágenes base64 de la base de datos
        // this.startVideo();
    },
    methods: {
        async loadModels() {
            const MODEL_URL = '/models';
            await faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL);
            await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
            await faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL);
            await faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL);
            this.modelsLoaded = true;
        },

        async getImage() {

            if(!this.areID){
                this.$fun.alert("Seleccione Area", "warning");
                return;
            }

            this.dialogAreID = false;
            this.count = 0;
            let imgInsert = [];
            this.dialog = true;

            await FacialReconognitionService.list(this.areID ,this.$fun.getUserID())
				.then(r => {
					if (r.status == 200) { 
						// console.log("r.data", r.data);
                        this.ArrayDNI = r.data;
                        this.ArrayDNI = [...new Map(this.ArrayDNI.map(item => [item.DNI, item])).values()];
					}
				});
            
            await GenUploadFiles.getCountFiles("routePrueba")
                .then(e => {
                    // console.log("e.data", e.data);
                    this.ArrayImg = e.data;
                })
                .catch(error => console.error("Error al obtener imagen:", error));

            imgInsert = this.ArrayDNI
                .filter(dniObj => !this.ArrayImg.includes(`${dniObj.DNI}.png`))
                .map(dniObj => `${dniObj.DNI}`);

            if (imgInsert.length > 0) {
                for (const dniInfo of imgInsert) {
                    const rsp = await _sHelper.getPhotoWorkerBase64(dniInfo, this.$fun.getUserID());
                    if (rsp.status === 200) {
                        const image = 'data:image/png;base64,' + rsp.data;
                        const resp = await GenUploadFiles.upload(image, dniInfo, 'routePrueba', true);
                        if (resp.status == 200) {
                            this.count++;
                        }
                    } else {
                        console.error(`No se pudo cargar la imagen de ${dniInfo.name}`);
                    }
                }
            }

            console.log("Se insertaron:", this.count, "imagenes");
            await _sGenParam.search({ PrmName: "routePrueba" },this.$fun.getUserID(),)
            .then(resp => {
                this.urlImgs = resp.data.PrmValue;
            });

            this.count = 0;

            for (const dniInfo of this.ArrayDNI) {
                try {
                    // Logica para que cargue las imagenes al modelo
                    await GenUploadFiles.getFile("routePrueba", dniInfo.DNI, true)
                    .then(async base64Image => {
                        // console.log("base64Image", dniInfo.DNI);
                        this.count++;

                        const img = await faceapi.fetchImage(base64Image);
                        const detections = await faceapi.detectSingleFace(img)
                            .withFaceLandmarks()
                            .withFaceDescriptor();

                        if (!detections) {
                            console.error(`No se detectó rostro en ${dniInfo.TRABAJADOR}`);
                            return;
                        }

                        this.labeledDescriptors.push(new faceapi.LabeledFaceDescriptors(dniInfo.TRABAJADOR, [detections.descriptor]));
                    })
                    .catch(error => console.error("Error al obtener imagen:", error));

                } catch (error) {
                    console.error(`Error cargando la imagen de ${dniInfo.TRABAJADOR}:`, error);
                }
            }

            console.log("Se analizaron:", this.count, "imagenes");
            this.dialog = false;
            this.startVideo();
        },

        async startVideo() {
            const video = this.$refs.video;
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: {} });
                video.srcObject = stream;
                video.addEventListener('play', this.recognizeFaces);
            } catch (error) {
                console.error("Error accediendo a la cámara:", error);
            }
        },

        startCamera() {
            const video = this.$refs.video;
            if (!this.isCameraActive) {
                navigator.mediaDevices.getUserMedia({ video: {} })
                    .then(stream => {
                        video.srcObject = stream;
                        video.addEventListener('play', this.recognizeFaces);
                        this.isCameraActive = true; // Marca la cámara como activa
                    })
                    .catch(error => {
                        console.error("Error accediendo a la cámara:", error);
                    });
            }
        },

        stopCamera() {
            const video = this.$refs.video;
            const stream = video.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop()); // Detenemos todas las pistas de video
            this.isCameraActive = false;
        },

        focusOnMainPerson() {
            this.focusOnMain = true; // Activamos el enfoque en la persona principal
        },

        showAllPeople() {
            this.focusOnMain = false; // Mostramos a todas las personas
        },

        async recognizeFaces() {
            const video = this.$refs.video;
            const canvas = this.$refs.canvas;

            // console.log(video.videoWidth, video.videoHeight);

            if (!video || !canvas || this.labeledDescriptors.length === 0 || !this.isCameraActive) return;

            const faceMatcher = new faceapi.FaceMatcher(this.labeledDescriptors, 0.6);
            const displaySize = { width: video.videoWidth, height: video.videoHeight };
            faceapi.matchDimensions(canvas, displaySize);

            setInterval(async () => {
                const detections = await faceapi.detectAllFaces(video, new faceapi.SsdMobilenetv1Options())
                    .withFaceLandmarks()
                    .withFaceDescriptors()
                    .withFaceExpressions(); // Añadimos las expresiones faciales

                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                if (this.focusOnMain && detections.length > 0) {
                    // Solo mostrar la primera persona detectada
                    const detection = detections[0];
                    const bestMatch = faceMatcher.findBestMatch(detection.descriptor);
                    this.recognizedPerson = bestMatch.toString();
                    const expressions = detection.expressions;
                    const highestExpression = Object.keys(expressions).reduce((a, b) => expressions[a] > expressions[b] ? a : b);
                    this.faceExpression = highestExpression;

                    faceapi.draw.drawDetections(canvas, [detection]);
                    faceapi.draw.drawFaceLandmarks(canvas, [detection]);
                    this.drawText(ctx, detection, bestMatch, highestExpression);
                } else {
                    // Mostrar todas las personas detectadas
                    detections.forEach(detection => {
                        const bestMatch = faceMatcher.findBestMatch(detection.descriptor);
                        this.recognizedPerson = bestMatch.toString();
                        const expressions = detection.expressions;
                        const highestExpression = Object.keys(expressions).reduce((a, b) => expressions[a] > expressions[b] ? a : b);
                        this.faceExpression = highestExpression;

                        faceapi.draw.drawDetections(canvas, [detection]);
                        faceapi.draw.drawFaceLandmarks(canvas, [detection]);
                        this.drawText(ctx, detection, bestMatch, highestExpression);
                    });
                }
            }, 100);
        },

        drawText(ctx, detection, bestMatch, highestExpression) {
            const { x, y } = detection.detection.box;
            ctx.fillStyle = "white";
            const text = `${bestMatch.toString()} - ${highestExpression}`;
            const textWidth = ctx.measureText(text).width;
            const padding = 10;

            const textY = y - 20;
            ctx.fillRect(x - padding, textY - 30, textWidth + padding * 2, 30);
            ctx.strokeStyle = "black";
            ctx.lineWidth = 2;
            ctx.strokeRect(x - padding, textY - 30, textWidth + padding * 2, 30);
            ctx.fillStyle = "red";
            ctx.fillText(text, x, textY - 10);
        }
    }
};
</script>

<style scoped>
video {
    width: 640px;
    max-width: 480px;
    display: block;
    margin: 10px auto;
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
}

p {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}

button {
    margin: 10px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
}
</style>